import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { MainItem } from './types';

export function detailGrid() {
  return {
    tab: {
      name: '到货记录明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.pic}/>;
          },
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          name: '供应商',
          key: 'vendorNo',
          width: 150,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'goodsPdaArrivalRecordDetailId',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/arrivalRecord/index/sub',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<MainItem[]>>({
          url: '/api/cloud/wms/rest/goods/arrival/record/detail',
          method: 'post',
          data: {
            goodsPdaArrivalRecordId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
