import { Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { detailGrid } from './detailGrid';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '到货记录';
          const exportType = 'goods_pda_arrival_record';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    pageId: '60030',
    grid: {
      columns: [
        {
          key: 'goodsPdaArrivalRecordNo',
          name: '扫描单号',
          width: 200,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'vendorNo',
          name: '供应商编码',
          width: 200,
        },
        {
          key: 'createTime',
          name: '扫描日期',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'goodsPdaArrivalRecordId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/arrivalRecord/index/main',
    },

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/goods/arrival/record/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [detailGrid()],
    },
  });
}
