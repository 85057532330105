import { ExportModal, ExportStore, Programme, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getOwner } from '../../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';

export class Store {
  constructor() {
    getOwner()
      .then((data) => this.programme.filterItems.addDict({ ownerId: data }));
  }

  public mainSubStructureModel = mainSubStructureModel(this);

  public programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: filterItems(),
    moduleName: 'egenieCloudWmsPurchaseOrderArrivalRecordIndex',
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });
}

const store = new Store();

export default class extends React.Component {
  render() {
    return (
      <>
        <ProgrammeComponent store={store.programme}/>
        <ExportModal store={store.exportStore}/>
      </>
    );
  }
}
