import type { FilterItemOptions } from 'egenie-utils';

export function filterItems(): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '1',
      data: [
        {
          value: '1',
          label: '扫描日期',
        },
      ],
    },
    {
      type: 'input',
      field: 'goodsPdaArrivalRecordNo',
      label: '扫描单号',
    },
    {
      type: 'select',
      field: 'ownerId',
      label: '货主',
    },
    {
      type: 'input',
      field: 'uniqueCode',
      label: '唯一码',
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'SKU编码',
    },
    {
      type: 'input',
      field: 'vendorNo',
      label: '供应商编码',
    },
  ];
}
